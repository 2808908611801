<footer>
  <hr>
<div class="container text-center">
<h1 class="parrafo">
      <address>
              <p> @CARS SALES S&M 2023 - Sociedad limitada - Compra Venta de coches ¡¡Al mejor precio!!</p>
              <p>
                  CL JACINTO Nº 8, 28903 - Madrid (Getafe)<br/>
                  Teléfono: +34 656577425 / +34 632205137
                  Contacto: carssalessm@gmail.com
                  <a class="nav-link" [routerLink]="['/contact']" routerLinkActive="router-link-active">Contacta con nosotros</a>
              </p>

      </address>
    </h1>
  <!--Añadiremos los iconos realizaremos lista de los elementos de los iconos de face, youtube etc-->
 <ul class="social nav justify-content-center">
     <li>
      <a href="https://youtube.com" target="_blank">
      <img src="/assets/img/icons/youtube.png" alt="Canal de la tienda en youtube">
      </a>
    </li>
    <li>
      <a href="https://twitter.com" target="_blank">
      <img src="/assets/img/icons/twitter.png" alt="Canal de la tienda en twitter">
      </a>
    </li>
    <li>
      <a href="https://facebook.com" target="_blank">
      <img src="/assets/img/icons/facebook.png" alt="Canal de la tienda en facebook">
      </a>
    </li>
  </ul>

</div>
</footer>
