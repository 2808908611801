<!-- Sidebar -->
<!--quitamos el bg-light por añadir la el estilo al color del sidebar lo quitamos del class-->
<div class="border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">
        <!--Asignando rutas a las partes del componente de sidebar-->

        <a [routerLink]="['/home']" routerLinkActive="router-link-active">Ir a mi Web</a>


    </div>
    <div class="list-group list-group-flush">
 
        <a [routerLink]="item.url" 
        *ngFor="let item of menuItems"
        routerLinkActive="router-link-active" 
         class="list-group-item list-group-item-action bg-light">
         <i class="{{ item.icon }}"></i>{{ item.label }}</a>
      
    </div>
</div>
<!-- /#sidebar-wrapper -->