

<!--https://marketplace.visualstudio.com/items?itemName=thekalinga.bootstrap4-vscode --trabajaremos con los snipers de bootstrap instalados al principio  dejaremos que el header ocupe todo el ancho nos basamos en https://www.w3schools.com/bootstrap4/default.asp -->
<!--el container-fluid quiere decir que ocupa todo el ancho-->
<!--USAREMOS LA ESTRUCTURA FLEX LA DEL BETTWEENN PARA HACER 3 COLUMNAS-->
<div class="container-fluid " >
    <div class="d-flex justify-center">
         
      <!--div -->
        
           <!--logotipo Columna1-->
           <img class="logotype" src="../header/portada_casrssalessm.png"  height="100" width="100" />
       <!--/div -->
           
       
   
       <!--usamos el flex para ordenar horizontal de izquiueda a derecha-->
       <!--div class="d-flex flex-row " 
           a para añadir enlace Columna3 ojo si escribo <a class="nav" me sale pa elejir el a y me rellena automaticamente > nos pondra de manera vertical y con flex lo pondremos de manera horizontal-->
           
             
                   
        <!--<a class="nav-link" href="#" style="color: rgb(255, 255, 255);">COCHES
         <img src="/assets/img/icons/coche2.png" 
         width="250"
         height="300"
         
         >
       </a>
       <a class="nav-link active" href="#" style="color: rgb(255, 255, 255);">MOTOS
         <img src="/assets/img/icons/moto1.PNG"
         width="250"
         height="300"
         >
        </a>
        <a class="nav-link active" href="#" style="color: rgb(255, 255, 255);">PROXIMAMENTE
         <img src="/assets/img/icons/mecanica2.PNG"
   width="250"
   height="300"
   > </a>-->
          
       </div>
       
    <!--</div>-->
    </div>
   
   