<!--VAMOS A TRABAJAR EL COMPONENTE DEL NAVBAR-->
<!--nos crearemos un custom clas bg para definir bien los componentes que no se vea sola pelota-->
<!--OJO PARA LOS ENLACES LO HACEMOS POR EL ROUTER LINK DE PUBLIC-->
<!--ojo antes teniamos <h1>Online shop parte publica</h1> como titulo para saber en que componente estamos-->
<nav class="navbar navbar-expand-sm navbar-dark custom-bg sticky-top">
  <button class="navbar-toggler"
   type="button" 
   data-toggle="collapse" 
   data-target="#navbarSupportedContent" 
   aria-controls="navbarSupportedContent"
    aria-expanded="false" 
    aria-label="Toggle navigation"
    >
    <span class="navbar-toggler-icon"></span>
    carssalessm.com</button> 
  <div class="collapse navbar-collapse" id="navbarSupportedContent">
    
    <ul class="navbar-nav">
      <li class="nav-item " *ngFor="let item of menuItems">
        <a class="nav-link" [routerLink]="item.url" routerLinkActive="router-link-active"
      
        data-toggle="collapse" 
        data-target="#navbarSupportedContent" 
       
         > {{ item.label }}
        
      </a>  
       
      </li>
    </ul>
    <ul class="navbar-nav ml-auto mt-2 mt-lg-0">
    
      <li class="nav-item">Contacta con nosotros: 
        <a href="https://wa.me/34656577425?text=CARS SALES S&M" target="_blank"> <img src="/assets/img/coches/whatsapp-fill.svg" style="width: 30px; height: 30px">
        
        </a>
      </li>
      <li class="nav-item" *ngIf="access && role == 'ADMIN'">
        <a class="nav-link" [routerLink]="['/admin']" routerLinkActive="router-link-active">Admin</a>
      </li>
<li class="nav-item" *ngIf="!access">
        <a class="nav-link" [routerLink]="['/login']" routerLinkActive="router-link-active"
        data-toggle="collapse" 
        data-target="#navbarSupportedContent" >Login</a>
      </li>
<li class="nav-item" *ngIf="!access">
        <a class="nav-link" [routerLink]="['/register']" routerLinkActive="router-link-active"
        data-toggle="collapse" 
        data-target="#navbarSupportedContent" >Registro</a>
      </li>
      <li class="nav-item dropdown"  *ngIf="access">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          {{userLabel}}
        </a>
        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Perfil</a>
          <a class="dropdown-item" href="#">Configuración</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()">Salir de la sesión</a>
        </div>
      </li>
    </ul>

  </div>
 
  </nav>